<template>
  <section>
    <content-header      
      :title="$t('loadClientInfo')"
    />
  <validation-observer
    tag="div"
    class="row mt-3"
    ref="loadClientInfoRef"
    novalidate
    style="padding-left: 30px;padding-right: 30px;"
  >
  <div class="container-fluid">
      <div class="main-page-container">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <validation-provider rules="required">
                <base-input
                  v-model="currentDocument.Filename"
                  :disabled="true"
                  :label="$t('clientInfoTemplate')"
                />
              </validation-provider>
            </div>
            
          </div>
        </div>
        <div class="col-md-12 mt-4 mb-3">
          <button class="btn btn-primary file-upload" @click="showUpload">
                <i class="fas fa-search mr-2" /> {{ $t('search') }}
              </button>
              <input
                ref="fileuploadRef"
                accept="csv"
                class="d-none"
                type="file"
                @change="handleFileChange"
              />
          <b-button
            class="text-nowrap float-right"
            variant="primary"
            @click="uploadDocuments"
          >
            <i class="fal fa-upload" /> {{ $t('loadcsv') }}
          </b-button>
        </div>
      </div>
    </div>
  </validation-observer>
</section>
</template>
<script>
import contentHeader from '@/components/Header';
import BaseInput from '@/components/BaseInput.vue';
import {loadClientInfo as _loadClientInfo} from '@/services/CompanyService';

import _generalApi from '@/modules/app/api.js';
import { mapGetters } from 'vuex';

export default {
  name: 'LoadClientInfoForm',
  components: {
    BaseInput,
    contentHeader
  },
  data() {
    return {
      companyId: null,
      currentDocument: {
        Filename: null,
        IsRequired: null,
        Content: null,
      },
    };
  },
  computed: {
    ...mapGetters('$_user', ['fullProfile', 'profile']),
  },
  async mounted() {
    const companyIds = Object.keys(this.fullProfile.companies).map((a) =>
      Number.parseInt(a),
    );

    this.companyId = Math.max(...companyIds);
  },
  methods: {
    handleFileChange(e) {
      const isValid = this.$refs.loadClientInfoRef.validate();

      if (isValid) {
        let docContent = e.target.files[0];
        this.currentDocument.Content = docContent;
        this.currentDocument.Filename = docContent.name;
      }
    },
    async uploadDocuments() {      
      if(Number.isFinite(this.companyId)) {
        if (this.currentDocument.Content == null) {
          this.ShowErrorToast(this.$t('fileRequired'));
          return;
        }

        var formData = new FormData();
        formData.append('file', this.currentDocument.Content);        
        
        await _loadClientInfo(this.companyId, formData)
          .then((resp) => {
            _generalApi.ConvertToBlob(resp.data);
            this.ShowSuccessToast(this.$t('operationCompleted'));
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }
    },
    showUpload() {
      this.$refs.fileuploadRef.click();
    },
  }
};
</script>
<style lang="scss" scoped>
::v-deep label {
  color: $color-font-secondary !important;
  font-size: 20px;
}

.file-upload {
  color: white !important;
  font-size: 16px;
}
</style>
