<template>
  <section>
    <button
      v-if="showGoBack"
      class="btn btn-outline-primary mt-3 mr-3 float-right"
      @click="$router.go(-1)"
    >
      <i class="far fa-arrow-left" /> {{ $t('goBack') }}
    </button>
    <content-header
      :class="{ 'p-0': showGoBack }"
      :title="$t('technicalSetup')"
    />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filter')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getTechnicians(1)"
        />
      </div>

      <separator
        v-if="totalTechnician"
        class="my-4"
        :text="$t('listOfTechnicians')"
      />
      <div
        v-if="totalTechnician"
        class="row mt-4"
      >
        <div class="col-md-4">
          <status-indicator-icon
            :count="totalTechnician"
            icon="fal fa-users"
            :title="$t('totalTechnicians')"
          />
        </div>
        <div class="col-md-4">
          <status-indicator-icon
            :count="totalActiveTechnician"
            icon="fal fa-users-cog"
            :title="$t('totalTechniciansConfigured')"
          />
        </div>
        <div class="col-md-4">
          <status-indicator-icon
            :count="totalTechnician - totalActiveTechnician"
            icon="fal fa-user-times"
            :title="$t('totalTechniciansNotConfigured')"
          />
        </div>

        <div
          v-for="(technician, index) in result.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :special-field-configuration="specialFieldConfiguration"
            :display-shifts="displayShifts"
            :index="index"
            :index-in-edit-mode="indexInEditMode"
            :languages="languages"
            :service-queues="serviceQueues"
            :have-representative-type="haveRepresentativeType"
            :have-communication-module = "haveCommunicationModule"
            :stations="stations"
            :value="technician"
            @edit="indexInEditMode = $event"
            @update-active-technician="totalActiveTechnician += $event"
          />
        </div>
        <div class="col-md-12 mt-3">
          <custom-pagination
            v-if="result.count > 0"
            id="pgPager"
            class="my-4"
            :data="result"
            :page-size="pageSize"
            @pagination-go-page="getTechnicians($event)"
            @pagination-rows-per-page="getTechnicians(pageIndex, $event)"
          />
        </div>
      </div>
      <CustomNotFound
        v-if="noResultsFound"
        :text="$t('noResultsFound')"
      />
    </div>
  </section>
</template>

<script>
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader.vue';
import FilterManager from '@/components/FilterManager';
import { modulesName } from '@/store.js';
import { getTechnicianSetup as _getTechnicianSetup } from '@/services/UserService';
import { getLanguages as _getLanguages, getSpecialFieldConfiguration as _getSpecialFieldConfiguration } from '@/services/CompanyService';
import StatusIndicatorIcon from '@/components/StatusIndicatorIcon';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult.vue';
import CustomPagination from '@/components/CustomPagination.vue';
import { getForTechnicianByLocationConfigurationId as _getServicesForTechnicianByLocationConfigurationId } from '@/services/ServicesService';
import { getStationList as _getStationByLocationConfigurationId } from '@/services/StationService';
import { getList as _getDisplayShiftList } from '@/services/DisplayShiftService';
import { getUserOffices as _getUserOffices, getUserById as _getUserById } from '@/services/UserService';
import { getCompany as _getCompany } from '@/services/CompanyService';
import {
  haveCommunicationModuleEnabled as _haveCommunicationModuleEnabled
} from '@/services/LocationService';

export default {
  name: 'TechnicianSetup',
  components: {
    contentHeader,
    SubHeader,
    FilterManager,
    StatusIndicatorIcon,
    CustomNotFound,
    Separator,
    SearchResult,
    CustomPagination,
  },
  data() {
    return {
      pageSize: 25,
      pageIndex: 1,
      selectedFilters: {
        locationConfiguration: [],
        userName: null,
      },
      locations: [],
      languages: [],
      result: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      totalTechnician: 0,
      totalActiveTechnician: 0,
      noResultsFound: false,
      prevRoute: null,
      serviceQueues: [],
      stations: [],
      displayShifts: [],
      locationConfigurationId: null,
      indexInEditMode: null,
      specialFieldConfiguration: null,
      haveRepresentativeType: false
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from ? from.name : null;
    });
  },
  async mounted() {

    const profile =
      this.$store.getters[`${modulesName.userModuleName}/fullProfile`];

    let userId = this.$route.params.userId || profile.userId;

    await _getCompany(profile.companyId).then(({data}) => {
        this.haveRepresentativeType = !!data.representativeCompanionType;
      });

    await _getUserOffices(userId).then((response) => {
      this.locations = response.data;
      this.loadEmployeeTechnicianSetup();
    });

    await _getLanguages(profile.companyId).then(({data}) => {
      this.languages = data;
    })

    await _getSpecialFieldConfiguration(profile.companyId).then(({data}) => {
      this.specialFieldConfiguration = data;
    })

    await this.loadDisplayShift();
  },
  computed: {
    showGoBack() {
      return this.prevRoute === 'employeeStatus';
    },
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'locationConfiguration',
          label: this.$t('office'),
          component: 'LocationFilterMultiSelect',
          options: this.locations,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          required: true,
          class: 'col-sm-12 col-md-4',
          initialValue: this.selectedFilters.locationConfiguration,
          onChanged: this.onLocationChanged,
        },
        {
          rules: '',
          name: 'userName',
          label: this.$t('userName'),
          component: 'BaseInput',
          placeholder: this.$t('userName'),
          class: 'col-sm-12 col-md-4',
          initialValue: this.selectedFilters.userName,
          value: this.selectedFilters.userName,
        },
      ];
    },
  },
  methods: {
    async loadEmployeeTechnicianSetup() {
      if(!this.$route.params.userId)
        return;

      await _getUserById(this.$route.params.userId).then(({data}) => {
        if(!data) return;

        this.selectedFilters.userName = data.userName;
        const location = this.locations.find(
          (loc) => loc.id === data.locationConfigurationId
        );
        this.onLocationChanged(location);
        this.getTechnicians(1);
      });
    },
    onLocationChanged(value) {
      this.selectedFilters.locationConfiguration = [value];
    },
    async loadServiceQueues(locationId) {
      await _getServicesForTechnicianByLocationConfigurationId(locationId).then(
        ({ data }) => {
          const automaticOption = data.find((x) => x.id === -2);
          if (automaticOption) automaticOption.name = this.$t('automatic');
          this.serviceQueues = data;
        },
      );
    },
    async loadStations(locationConfigurationId) {
      await _getStationByLocationConfigurationId(locationConfigurationId).then(
        ({ data }) => (this.stations = data),
      );
    },
    async loadDisplayShift() {
      await _getDisplayShiftList().then(
        ({ data }) => (this.displayShifts = data),
      );
    },
    async getTechnicians(pageIndex = null, pageSize = null) {
      if (!this.selectedFilters.locationConfiguration.length)
        return this.ShowToast('Error', `${this.$t('officeRequired')}`, 'error');

      this.indexInEditMode = null;

      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const locationConfigurationId =
        this.selectedFilters.locationConfiguration[0].id || null;

      if(locationConfigurationId !== null) {
        await _haveCommunicationModuleEnabled(locationConfigurationId).then(({ data }) => {
          this.haveCommunicationModule = data;
        });
      }

      const payload = {
        locationConfigurationId,
        user: this.selectedFilters.userName || null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };

      await _getTechnicianSetup(payload)
        .then(async ({ data }) => {
          this.result = data.data;
          this.totalTechnician = data.totalTechnician;
          this.totalActiveTechnician = data.totalActiveTechnician;
          this.noResultsFound = !this.result.data.length;
          if (this.locationConfigurationId != locationConfigurationId) {
            this.locationConfigurationId = locationConfigurationId;
            await this.loadServiceQueues(locationConfigurationId);
            await this.loadStations(locationConfigurationId);
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
