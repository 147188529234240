<template>
  <div class="p-3 card border-light listview">
    <validation-observer ref="form" novalidate>
      <div class="row">
        <div class="col-md-6 col-xl-3">
          <div class="cell">
            <label>{{ $t('userName') }}: </label>
            <span>{{ value.userName }}</span>
          </div>
          <div class="cell">
            <label>{{ $t('row') }}: </label>
            <span>
              {{
                value.serviceQueueId != -2
                  ? value.serviceQueueName
                  : $t('automatic')
              }}
            </span>
          </div>
          <div class="cell">
            <label>{{ $t('fixedRow') }}: </label>
            <span>{{ $t(value.fixedServiceQueue ? 'yes' : 'no') }}</span>
          </div>
          <div class="cell d-flex">
            <label
              class="align-self-center"
              :style="{ 'margin-top': isEditMode ? '-10px' : '0' }"
              >{{ $t('baseRow') }}:
            </label>
            <span v-if="!isEditMode">
              {{
                value.baseServiceQueueId != -2
                  ? value.baseServiceQueueName
                  : $t('automatic')
              }}
            </span>
            <filter-select
              v-if="isEditMode"
              v-model="model.baseServiceQueueId"
              fieldtext="name"
              fieldvalue="id"
              label=""
              style="width: 100%"
              :options="serviceQueues"
              class="pl-2"
            />
          </div>
          <div class="cell">
            <label>{{ $t('fixedBaseRow') }}: </label>
            <span v-if="!isEditMode">
              {{ $t(value.fixedBaseServiceQueue ? 'yes' : 'no') }}
            </span>
            <b-form-checkbox
              style="margin-left: 85px"
              v-if="isEditMode"
              v-model="model.fixedBaseServiceQueue"
              switch
            >
            </b-form-checkbox>
          </div>
          <div class="cell">
            <label>
              {{
                specialFieldConfiguration.specialField1Name ||
                $t('specialField1')
              }}:
            </label>
            <span v-if="!isEditMode && specialFieldConfiguration.specialField1"
              >{{ value.specialField1 }}
            </span>
            <validation-provider
              v-if="isEditMode && specialFieldConfiguration.specialField1"
              tag="div"
              v-slot="{ errors }"
              :rules="{
                required: specialFieldConfiguration.specialField1Required,
              }"
            >
              <BaseInput
                v-model="model.specialField1"
                :error="errors[0]"
                :error-msg="$t('invalidField')"
              />
            </validation-provider>
          </div>
          <div class="cell d-flex">
            <label
              class="align-self-center"
              :style="{ 'margin-top': isEditMode ? '-10px' : '0' }"
            >
              {{ $t('type') }}:
            </label>
            <span v-if="!isEditMode">{{ currentRepresentativeType }}</span>
            <filter-select
              :disabled="!haveRepresentativeType"
              v-if="isEditMode"
              v-model="model.representativeTypeId"
              fieldtext="text"
              fieldvalue="value"
              label=""
              style="width: 100%"
              :options="representativeCompanionTypeOptions"
              class="pl-2"
            />
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="cell">
            <label>{{ $t('name') }}: </label>
            <span>{{ value.fullName }}</span>
          </div>
          <div class="cell d-flex">
            <label
              class="align-self-center"
              :style="{ 'margin-top': isEditMode ? '-10px' : '0' }"
            >
              {{ $t('station') }}:
            </label>
            <span v-if="!isEditMode">{{ value.stationName }}</span>
            <filter-select
              v-if="isEditMode"
              v-model="model.stationId"
              fieldtext="name"
              fieldvalue="id"
              label=""
              :options="stations"
              style="width: 100%"
              class="pl-2"
            />
          </div>
          <div class="cell">
            <label>{{ $t('fixedStation') }}: </label>
            <span v-if="!isEditMode">{{
              $t(value.fixedStation ? 'yes' : 'no')
            }}</span>
            <b-form-checkbox
              style="margin-left: 85px"
              v-if="isEditMode"
              v-model="model.fixedStation"
              switch
            >
            </b-form-checkbox>
          </div>
          <div class="cell">
            <label>{{ $t('individualCallAvailable') }}: </label>
            <span v-if="!isEditMode">{{
              $t(value.activeBellFlag ? 'yes' : 'no')
            }}</span>
            <b-form-checkbox
              style="margin-left: 195px"
              v-if="isEditMode"
              v-model="model.activeBellFlag"
              switch
            >
            </b-form-checkbox>
          </div>

          <div class="cell">
            <label>{{ $t('canCloseTurn') }}</label>
            <span v-if="!isEditMode">{{
              $t(value.canCloseTurnFromList ? 'yes' : 'no')
            }}</span>
            <b-form-checkbox
              style="margin-left: 145px"
              v-if="isEditMode"
              v-model="model.canCloseTurnFromList"
              switch
            >
            </b-form-checkbox>
          </div>

          <div class="cell d-flex">
            <label
              class="align-self-center"
              :style="{ 'margin-top': isEditMode ? '-10px' : '0' }"
            >
              {{ $t('viewListOfTurns') }}:
            </label>
            <span v-if="!isEditMode">{{ value.displayShiftList }}</span>
            <filter-select
              v-if="isEditMode"
              v-model="model.displayShiftListId"
              :clearable="false"
              fieldtext="name"
              fieldvalue="id"
              label=""
              style="width: 100%"
              :options="displayShifts"
              class="pl-2"
            />
          </div>
          <div class="cell">
            <label>
              {{
                specialFieldConfiguration.specialField2Name ||
                $t('specialField2')
              }}:
            </label>
            <span v-if="!isEditMode">{{ currentSpecialField2 }}</span>
            <validation-provider
              v-if="isEditMode && specialFieldConfiguration.specialField2"
              tag="div"
              v-slot="{ errors }"
              :rules="{
                required: specialFieldConfiguration.specialField2Required,
              }"
            >
              <filter-select
                v-model="model.specialField2"
                fieldtext="name"
                fieldvalue="id"
                label=""
                style="width: 100%"
                :options="specialFieldConfiguration.specialField2Options"
                is-multiple
                :error="errors[0]"
                :error-msg="$t('mustSelectOne')"
              />
            </validation-provider>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="cell">
            <label>{{ $t('removeTurns') }}: </label>
            <span v-if="!isEditMode">{{
              $t(value.removeTurnFlag ? 'yes' : 'no')
            }}</span>
            <b-form-checkbox
              style="margin-left: 105px"
              v-if="isEditMode"
              v-model="model.removeTurnFlag"
              switch
            >
            </b-form-checkbox>
          </div>
          <div class="cell">
            <label>{{ $t('available') }}: </label>
            <span v-if="!isEditMode">{{
              $t(value.active ? 'yes' : 'no')
            }}</span>
            <b-form-checkbox
              style="margin-left: 85px"
              v-if="isEditMode"
              v-model="model.active"
              switch
            >
            </b-form-checkbox>
          </div>
          <div class="cell">
            <label>{{ $t('activeInternalChat') }}: </label>
            <span v-if="!isEditMode">{{
              $t(value.internalChatActive ? 'yes' : 'no')
            }}</span>
            <b-form-checkbox
              style="margin-left: 135px"
              v-if="isEditMode"
              :disabled="!haveCommunicationModule"
              v-model="model.internalChatActive"
              switch
            >
            </b-form-checkbox>
          </div>
          <div class="cell">
            <label>{{ $t('languages') }}: </label>
            <span v-if="!isEditMode">{{ currentLanguages }}</span>
            <filter-select
              v-if="isEditMode"
              v-model="model.languageIds"
              fieldtext="name"
              fieldvalue="id"
              label=""
              style="width: 100%"
              :options="languages"
              is-multiple
            />
          </div>
          <div class="cell">
            <label
              >{{ specialFieldConfiguration.categoryName || $t('categories') }}:
            </label>
            <span v-if="!isEditMode">{{ currentCategories }}</span>
            <filter-select
              v-if="isEditMode && specialFieldConfiguration.category"
              v-model="model.categoryIds"
              fieldtext="name"
              fieldvalue="id"
              label=""
              style="width: 100%"
              :options="specialFieldConfiguration.categories"
              is-multiple
            />
          </div>

          <div class="cell">
            <label>
              {{
                specialFieldConfiguration.specialField3Name ||
                $t('specialField3')
              }}:
            </label>
            <span v-if="!isEditMode">{{ currentSpecialField3 }}</span>
            <validation-provider
              v-if="isEditMode && specialFieldConfiguration.specialField3"
              tag="div"
              v-slot="{ errors }"
              :rules="{
                required: specialFieldConfiguration.specialField3Required,
              }"
            >
              <filter-select
                v-model="model.specialField3"
                fieldtext="name"
                fieldvalue="id"
                label=""
                style="width: 100%"
                :options="specialFieldConfiguration.specialField3Options"
                is-multiple
                :error="errors[0]"
                :error-msg="$t('mustSelectOne')"
              />
            </validation-provider>
          </div>
        </div>
        <div class="col-md-6 col-xl-3 text-right pt-3">
          <button
            v-if="!isEditMode"
            @click="edit()"
            class="btn btn-outline-primary cursor-pointer"
          >
            {{ $t('edit') }}
          </button>
          <button
            v-if="isEditMode"
            class="btn btn-primary"
            style="width: 140px"
            @click="onSubmit()"
          >
            <i class="far fa-save" /> {{ $t('save') }}
          </button>
          <br />
          <button
            v-if="isEditMode"
            class="btn mt-2"
            style="background-color: #707070; width: 140px; color: white"
            @click="$emit('edit', null)"
          >
            <i class="fas fa-ban" /> {{ $t('cancel') }}
          </button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { updateTechnicianSetup } from '@/services/UserService';
import BaseInput from '@/components/BaseInput.vue';
import CompanySpecialFieldType from '@/constants/CompanySpecialFieldType';
import RepresentativeCompanionTypeOptions from '@/constants/RepresentativeCompanionTypeOptions';

export default {
  name: 'SearchResult',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    displayShifts: {
      type: Array,
      default: () => [],
    },
    languages: {
      type: Array,
      default: () => [],
    },
    specialFieldConfiguration: {
      type: Object,
      default: () => ({}),
    },
    stations: {
      type: Array,
      default: () => [],
    },
    serviceQueues: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: null,
    },
    indexInEditMode: {
      type: Number,
      default: null,
    },
    haveRepresentativeType: {
      type: Boolean,
      default: false,
    },
    haveCommunicationModule: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: {},
      representativeCompanionTypeOptions: RepresentativeCompanionTypeOptions,
    };
  },
  computed: {
    isEditMode() {
      return this.index === this.indexInEditMode;
    },
    currentLanguages() {
      return this.languages
        .filter((x) => this.value.languageIds.includes(x.id))
        .map((x) => x.name)
        .join(', ');
    },
    currentCategories() {
      return this.specialFieldConfiguration.categories
        ?.filter((x) => this.value.miscData.find((m) => m.id == x.id))
        .map((x) => x.name)
        .join(', ');
    },
    currentSpecialField2() {
      return this.specialFieldConfiguration.specialField2Options
        ?.filter((x) => this.value.miscData.find((m) => m.id == x.id))
        .map((x) => x.name)
        .join(', ');
    },
    currentSpecialField3() {
      return this.specialFieldConfiguration.specialField3Options
        ?.filter((x) => this.value.miscData.find((m) => m.id == x.id))
        .map((x) => x.name)
        .join(', ');
    },
    currentRepresentativeType() {
      return this.value.representativeTypeId
        ? this.representativeCompanionTypeOptions.find(
            (m) => m.value == this.value.representativeTypeId,
          ).text
        : '';
    },
  },
  methods: {
    edit() {
      this.$emit('edit', this.index);

      this.model = {
        ...this.value,
        categoryIds: this.value.miscData
          .filter(
            (x) => x.specialField == CompanySpecialFieldType.Categorization,
          )
          .map((x) => x.id),
        specialField2: this.value.miscData
          .filter(
            (x) => x.specialField == CompanySpecialFieldType.SpecialField2,
          )
          .map((x) => x.id),
        specialField3: this.value.miscData
          .filter(
            (x) => x.specialField == CompanySpecialFieldType.SpecialField3,
          )
          .map((x) => x.id),
      };
    },
    async onSubmit() {
      if (!this.model.displayShiftListId) {
        return this.ShowToast(
          'Error',
          this.$t('youMustSelectAnOptionOfViewShiftList'),
          'error',
        );
      }

      if (!(await this.$refs.form.validate())) return;

      const companyMiscIds = [
        ...this.model.categoryIds,
        ...this.model.specialField2,
        ...this.model.specialField3,
      ];

      updateTechnicianSetup({ ...this.model, companyMiscIds })
        .then(() => {
          this.ShowSuccessSaveToast();

          if (this.value.baseServiceQueueId != this.model.baseServiceQueueId) {
            this.value.serviceQueueId = this.model.baseServiceQueueId;
            this.value.baseServiceQueueId = this.model.baseServiceQueueId;
            const serviceQueue = this.serviceQueues.find(
              (x) => x.id === this.model.baseServiceQueueId,
            );
            const name =
              this.model.baseServiceQueueId != -2
                ? serviceQueue?.name
                : this.$t('automatic');
            this.value.serviceQueueName = name;
            this.value.baseServiceQueueName = name;
          }

          if (this.value.active != this.model.active) {
            this.$emit('update-active-technician', this.model.active ? 1 : -1);
            this.value.active = this.model.active;
          }

          const station = this.stations.find(
            (x) => x.id === this.model.stationId,
          );
          const displayShiftList = this.displayShifts.find(
            (x) => x.id === this.model.displayShiftListId,
          );

          this.value.fixedServiceQueue = this.model.fixedBaseServiceQueue;
          this.value.fixedBaseServiceQueue = this.model.fixedBaseServiceQueue;
          this.value.stationId = this.model.stationId;
          this.value.stationName = station?.name;
          this.value.fixedStation = this.model.fixedStation;

          this.value.activeBellFlag = this.model.activeBellFlag;
          this.value.displayShiftListId = this.model.displayShiftListId;
          this.value.representativeTypeId = this.model.representativeTypeId;
          this.value.displayShiftList = displayShiftList?.name;
          this.value.removeTurnFlag = this.model.removeTurnFlag;
          this.value.internalChatActive = this.model.internalChatActive;
          this.value.languageIds = this.model.languageIds;
          this.value.specialField1 = this.model.specialField1;
          this.value.canCloseTurnFromList = this.model.canCloseTurnFromList;

          this.value.miscData = [
            ...this.model.categoryIds.map((x) => ({
              id: x,
              specialField: CompanySpecialFieldType.Categorization,
            })),
            ...this.model.specialField2.map((x) => ({
              id: x,
              specialField: CompanySpecialFieldType.SpecialField2,
            })),
            ...this.model.specialField3.map((x) => ({
              id: x,
              specialField: CompanySpecialFieldType.SpecialField3,
            })),
          ];
          
          this.$emit('edit', null);
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.ShowToast('Error', error.response.data.message, 'error');
          } else {
            this.ShowToast('Error', error.message, 'error');
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.listview .cell {
  clear: left;
}

.listview .cell label {
  float: left;
  font-weight: bold;
}

.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
</style>
